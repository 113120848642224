<template>
  <div style="background: #fff">
    <div class="menu-modal"></div>
    <div class="menu-header">
      <img width="229" :src="menu_logo" alt />
    </div>
    <div class="menu-title center p-l-r">
      <span class="menu-title-line"></span>
      <span>产品</span>
    </div>
    <div class="menu-main center p-l-r">
      <div
        v-for="(item, index) in mainArr"
        :key="item.title"
        @click="jump(item.path, item.title)"
        @mousemove="mouseover(index)"
        @mouseout="mouseout(index)"
      >
        <div class="img-box">
          <img :class="item.isHover && 'hover-img'" :src="item.url" alt />
          <div v-if="item.isHover">
            <span>{{ item.text || "立即使用" }}></span>
          </div>
        </div>
        <div>
          <h3>
            {{ item.title }}
            <span v-if="showTestText(item.title)" style="color: red"
              >测试版</span
            >
          </h3>
          <p>{{ item.content }}</p>
        </div>
      </div>
      <div style="visibility: hidden"></div>
    </div>
  </div>
</template>
<script>
import product_info from "@/store/product_info.js";
export default {
  name: "Menu",
  dec: "主页导航",
  data() {
    return {
      menu_logo: product_info.menu_logo,
      mainArr: [
        {
          title: "上市及债券企业分析",
          url: require("./img/ic_one@2x.png"),
          content:
            "上市企业及信用债发债主体财务报表分析，不包含金融机构与事业单位。",
          path: "/ListDebt",
          isHover: false,
        },
        {
          title: "企业分析",
          url: require("./img/ic_one@2x.png"),
          content:
            "自行上传财务报表进行分析，不包含金融机构和事业单位等特殊类型企业。",
          path: "/Custom",
          isHover: false,
        },
        {
          title: "记录查询",
          url: require("./img/ic_six@2x.png"),
          content: "查询已上传财务报表的历史企业记录",
          path: "/database/data-manager",
          isHover: false,
        },

        {
          title: "行业数据库",
          url: require("./img/ic_eight@2x.png"),
          content: "供行业研究分析的行业经济指标。",
          path: "/database/industry",
          isHover: true,
        },

        //
        {
          title: "事业单位分析",
          url: require("./img/ic_sev@2x.png"),
          content: "针对事业单位专题分析",
          path: "/CauseSearch",
          isHover: false,
        },
      ],
    };
  },
  methods: {
    jump(path, title) {
      // if (path === undefined) return this.$Message.warning("敬请期待");
      if (
        title == "上市及债券企业分析" ||
        title == "企业分析" ||
        title == "小微企业分析" ||
        title == "事业单位分析"
      ) {
        this.$store.commit("setBackSearchPath", path);
        this.$store.commit("setSearchTitle", title);
      }

      this.$router.push(path);
    },
    mouseover(index) {
      this.mainArr[index].isHover = true;
    },
    mouseout(index) {
      this.mainArr[index].isHover = false;
    },
    showTestText(title) {
      var boolean = false;
      // if (title == "小微企业分析" || title == "事业单位分析") boolean = true;
      if (title == "小微企业分析") boolean = true;
      return boolean;
    },
  },
  created() {
    // 清除联动效果

    this.$store.commit("setListingParame", { name: "report_type", value: "" });
    this.$store.commit("setListingParame", { name: "report_time", value: "" });
    this.$store.commit("setListingParame", { name: "selectedYear", value: 0 });
  },
  computed: {},
};
</script>

<style lang="less">
.center {
  width: 1100px;
  margin: 0 auto;
}
.p-l-r {
  padding: 0 15px;
}
.menu-modal {
  background: url(../../assets/img/search/bg.png) repeat center center;
  background-size: cover;
  width: 100%;
  height: 60px;
  position: fixed;
  left: 0;
  top: 0;
}
.menu-header {
  width: 100%;
  margin-top: 93px;

  > img {
    display: block;
    margin: 0 auto;
  }
}
.menu-title {
  margin-top: 6px;
  font-size: 16px;
  font-family: SimHei;
  font-weight: 400;
  color: rgba(42, 39, 39, 1);
  position: relative;
  text-indent: 6px;
  .menu-title-line {
    position: absolute;
    width: 2px;
    background: #1270ff;
    height: 16px;
    left: 16px;
    top: 3px;
  }
}
.menu-main {
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 291px;
    min-height: 121px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(241, 241, 241, 1);
    padding-bottom: 4px;
    opacity: 1;
    border-radius: 4px;
    margin-bottom: 30px;

    img {
      width: 54px;
      height: 54px;
      margin: 30px 16px 0 16px;
      transition: margin 0.5s;
    }
    .hover-img {
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .img-box {
      text-align: center;
      span {
        font-size: 12px;
        font-family: SimHei;
        font-weight: 400;
        color: rgba(18, 112, 255, 1);
      }
    }
    h3 {
      display: block;
      width: 168px;
      font-size: 15px;
      //   font-family:SimHei;
      font-weight: 700;
      margin: 13px 0;
      letter-spacing: 1px;
      color: #515a6e;
    }
    > div {
      height: 100%;
    }
    p {
      display: block;
      font-size: 12px;

      font-weight: 400;
      letter-spacing: 2px;
      color: rgba(132, 129, 129, 1);
    }
  }
  > div:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 4px;
  }
}
@media (max-width: 460px) {
  .menu-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-title {
    width: 100%;
  }
}
</style>
